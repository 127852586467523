<template>
	<!-- 个人中心 分销模块 组件 -->
	<div>
		<a-card :bordered="false" class="header-solid h-full mb-24" style="overflow: hidden" :bodyStyle="{ padding: 0 }">
			<template #title>
				<a-row type="flex" align="middle">
					<a-col :span="24" :md="12">
						<h5 class="font-semibold m-0">我的收益</h5>
					</a-col>
				</a-row>
			</template>
			<a-table
				:columns="earningsTableColumns"
				:data-source="earningsTableData"
				:pagination="false"
				:loading="earningsTableLoading">
				<template slot="dengji" slot-scope="dengji">
					<a-tag class="tag-status" :class="dengji.level > 0 ? 'ant-tag-primary' : 'ant-tag-muted'">
						{{ dengji.text }}
					</a-tag>
				</template>
				<template slot="editBtn" slot-scope="row">
					<!-- {{ row }} -->
					<a-button type="link" :data-id="row.key" @click="Withdrawal">提现</a-button>
				</template>
			</a-table>
		</a-card>

		<a-card :bordered="false" class="header-solid h-full" style="overflow: hidden" :bodyStyle="{ padding: 0 }">
			<template #title>
				<a-row type="flex" align="middle">
					<a-col :span="24" :md="24">
						<a-radio-group v-model="tabsId" size="small">
							<a-radio-button :value="item.id" v-for="(item, index) in tabsList" :key="'table_tabs_' + item.id">{{
								item.title
							}}</a-radio-button>
						</a-radio-group>
						邀请链接：{{ qrcodeValue }}
						<a-button style="margin-left: 5px; padding-top: 5px" size="small" @click="copy">复制</a-button>
					</a-col>
				</a-row>
			</template>
			<div v-if="tabsId === 1">
				<a-table
					:columns="inviteTableColumns"
					:data-source="inviteTableData"
					:pagination="false"
					:loading="inviteTableLoading">
					<template slot="avatar" slot-scope="avatar">
						<a-avatar shape="square" :src="avatar" />
					</template>
				</a-table>
			</div>
			<div v-if="tabsId === 2">
				<a-table
					:columns="brokerageTableColumns"
					:data-source="brokerageTableData"
					:pagination="false"
					:loading="brokerageTableLoading"></a-table>
			</div>
		</a-card>

		<!-- 提现 @ok="handleOk"-->
		<a-modal v-model="visible" title="申请提现" okText="确定" cancelText="取消" @ok="onSubmit" @cancel="resetForm">
			<a-form-model ref="ruleForm" :model="form" :rules="rules" :label-col="labelCol" :wrapper-col="wrapperCol">
				<a-form-model-item ref="real_name" label="真实姓名" prop="real_name">
					<a-input v-model="form.real_name" v-decorator="rules.real_name" />
				</a-form-model-item>
				<a-form-model-item label="支付宝账号" prop="card_no">
					<a-input v-model="form.card_no" v-decorator="rules.card_no" />
				</a-form-model-item>
			</a-form-model>
		</a-modal>

		<a-modal v-model="sumVisible" title="提现金额" okText="确定" cancelText="取消" @ok="onSubmitSum">
			<a-form-model
				ref="ruleFormSum"
				:model="sumForm"
				:rules="rulesSum"
				:label-col="labelCol"
				:wrapper-col="wrapperCol">
				<a-form-model-item label="提现金额" prop="money">
					<a-input v-model="sumForm.money" />
				</a-form-model-item>
			</a-form-model>
		</a-modal>
	</div>
</template>

<script>
	import { apiList } from "@/api/api";
	import { mapState, mapGetters, mapMutations, mapActions } from "vuex";
	export default {
		props: {
			data: {
				type: Array,
				default: () => [],
			},
			columns: {
				type: Array,
				default: () => [],
			},
		},
		data() {
			var realName = (rule, value, callback) => {
				let reg = /^[\u4E00-\u9FA5]{2,10}(·[\u4E00-\u9FA5]{2,10}){0,2}$/;
				if (!value) {
					//如果输入为空直接返回
					callback();
				} else {
					//如果字符串 string 中含有与 RegExpObject 匹配的文本，则返回 true，否则返回 false。
					if (reg.test(value)) {
						//匹配成功返回
						callback();
					} else {
						//匹配不成功返回错误显示
						callback(new Error("真实姓名格式有误，请重新输入！"));
					}
				}
			};

			var zfbAccount = (rule, value, callback) => {
				let reg = /^(?:1[3-9]\d{9}|[a-zA-Z\d._-]*\@[a-zA-Z\d.-]{1,10}\.[a-zA-Z\d]{1,20})$/;
				if (!value) {
					//如果输入为空直接返回
					callback();
				} else {
					//如果字符串 string 中含有与 RegExpObject 匹配的文本，则返回 true，否则返回 false。
					if (reg.test(value)) {
						//匹配成功返回
						callback();
					} else {
						//匹配不成功返回错误显示
						callback(new Error("支付宝账号格式有误，请重新输入！"));
					}
				}
			};

			return {
				// 个人收益
				earningsTableColumns: [
					{
						title: "可提现金额",
						dataIndex: "ketixian",
						class: "text-muted",
					},
					{
						title: "今日收益",
						dataIndex: "jinri",
						class: "text-muted",
					},
					{
						title: "累计收益",
						dataIndex: "leiji",
						class: "text-muted",
					},
					{
						title: "角色认证状态",
						dataIndex: "dengji",
						scopedSlots: { customRender: "dengji" },
						class: "text-muted",
					},
					{
						title: "",
						scopedSlots: { customRender: "editBtn" },
						width: 50,
					},
				],
				earningsTableData: [],
				// 邀请列表
				inviteTableColumns: [
					{
						title: "昵称",
						dataIndex: "id",
						class: "text-muted",
					},
					{
						title: "头像",
						dataIndex: "avatar",
						scopedSlots: { customRender: "avatar" },
					},
					{
						title: "UID",
						dataIndex: "nickname",
						class: "text-muted",
					},
					{
						title: "关系",
						dataIndex: "relation",
						class: "text-muted",
					},
					// {
					// 	title: "累计消费",
					// 	dataIndex: "expense",
					// 	class: "text-muted"
					// },
					{
						title: "邀请时间",
						dataIndex: "time",
						class: "text-muted",
					},
				],
				inviteTableData: [],
				// 分佣列表
				brokerageTableColumns: [
					{
						title: "用户ID",
						dataIndex: "id",
						class: "text-muted",
					},
					{
						title: "用户昵称",
						dataIndex: "nickname",
						class: "text-muted",
					},
					{
						title: "关系",
						dataIndex: "relation",
						class: "text-muted",
					},
					{
						title: "消费类型",
						dataIndex: "type",
						class: "text-muted",
					},
					{
						title: "充值金额",
						dataIndex: "money",
						class: "text-muted",
					},
					{
						title: "分佣",
						dataIndex: "brokerage",
						class: "text-muted",
					},
					{
						title: "充值时间",
						dataIndex: "time",
						class: "text-muted",
					},
				],
				brokerageTableData: [],
				tabsId: 1,
				tabsList: [
					{ id: 1, title: "邀请列表" },
					{ id: 2, title: "佣金明细" },
				],
				earningsTableLoading: true,
				inviteTableLoading: true,
				brokerageTableLoading: true,
				qrcodeValue: "",

				// 提现
				visible: false,
				labelCol: { span: 6 },
				wrapperCol: { span: 18 },
				other: "",
				form: {
					real_name: "",
					type: "alipay ",
					card_no: "",
				},
				rules: {
					real_name: [
						"real_name",
						{ required: true, message: "请输入真实姓名", trigger: "blur" },
						{ validator: realName },
						// { min: 3, max: 5, message: 'Length should be 3 to 5', trigger: 'blur' },
					],
					card_no: [
						"card_no",
						{ required: true, message: "请输入支付宝账号", trigger: "blur" },
						{ validator: zfbAccount },
					],
				},
				sumForm: {
					money: "",
					type: "alipay",
					platform: "wxMiniProgram",
				},
				rulesSum: {
					money: [{ required: true, message: "请输入金额", trigger: "blur" }],
				},
				sumVisible: false,
			};
		},
		watch: {
			tabsId(newValue, oldValue) {
				console.log(newValue, "切换分类");
			},
		},
		computed: {
			...mapGetters("user", ["token", "userInfo"]),
		},
		created() {
			console.log("分销");
			this.getInviteData();
			this.getBrokerageData();
			this.getResellerData();
			let id = this.$store.state.user.userInfo.id;
			// let spm = id + '.1.0.1.2'
			// // this.qrcodeValue = window.location.origin + window.location.pathname + `?${this.$SIGN}#/pages/role/index?scene=${spm}`
			// this.qrcodeValue = this.$BASE_API + `/h5/#/pages/role/init?scene=` + spm
			const spm = this.userInfo.id + ".1.0.4.3";
			let sign = window.location.search.replace(/\?/g, "");
			this.qrcodeValue = this.$BASE_API + `?${sign}#/?share=${spm}`;
		},
		methods: {
			// 获取个人分销商数据
			getResellerData() {
				this.$http("place.data").then((res) => {
					if (res.code === 1) {
						this.earningsTableData = [
							{
								key: 1,
								ketixian: res.data.money,
								jinri: res.data.today_reseller_money,
								leiji: res.data.reseller_money,
								dengji: {
									text: res.data.reseller ? res.data.reseller.reseller_json.name : "无",
									level: res.data.reseller ? res.data.reseller.reseller_json.level : 0,
								},
							},
						];
					}
					this.earningsTableLoading = false;
				});
			},
			// 获取佣金数据
			getBrokerageData() {
				this.$http("place.brokerage").then((res) => {
					if (res.code === 1) {
						if (res.data.list && res.data.list.length) {
							const arr = [];
							res.data.list.map((item) => {
								arr.push({
									key: item.id,
									id: item.id,
									nickname: item.nickname,
									relation: item.type_text,
									type: item.order_type_text,
									money: item.pay_money,
									brokerage: item.money,
									time: item.createtime,
								});
							});
							this.brokerageTableData = this.brokerageTableData.concat(arr);
						}
					}
					this.brokerageTableLoading = false;
				});
			},
			// 获取邀请数据
			getInviteData() {
				this.$http("place.team").then((res) => {
					if (res.code === 1) {
						if (res.data.reseller_user && res.data.reseller_user.length) {
							const arr = [];
							res.data.reseller_user.map((item) => {
								arr.push({
									key: item.id,
									// id: item.id,
									id: this.$store.state.user.userInfo.nickname,
									avatar: item.avatar,
									nickname: item.nickname,
									relation: item.type == 1 ? "直推" : "间推",
									time: item.createtime,
								});
							});
							this.inviteTableData = this.inviteTableData.concat(arr);
						}
					}
					this.inviteTableLoading = false;
				});
			},
			// 复制邀请链接
			copy() {
				const input = document.createElement("input");
				input.setAttribute("readonly", "readonly");
				// input.setAttribute('value', window.location.protocol + "//" + window.location.host + "?inviteCode=" + inviteCode.value)
				input.setAttribute("value", this.qrcodeValue);
				document.body.appendChild(input);
				input.select();
				if (document.execCommand("copy")) {
					document.execCommand("copy");
					this.$message.success("复制成功");
				}
				document.body.removeChild(input);
			},

			// 提现
			Withdrawal() {
				this.visible = true;
			},
			onSubmit() {
				this.$refs.ruleForm.validate((valid) => {
					if (valid) {
						this.edit(this.form);
						this.visible = false;
						this.sumVisible = true;
					} else {
						console.log("error submit!!");
						return false;
					}
				});
			},
			resetForm() {
				this.$refs.ruleForm.resetFields();
			},
			// 提现
			edit(data) {
				this.$http("user.edit", data).then((res) => {
					if (res.code === 1) {
						console.log(res.data);
						// this.$message.success('提交申请成功');
					}
				});
			},
			// 金额
			apply(data) {
				this.$http("user.apply", data).then((res) => {
					if (res.code === 1) {
						this.$message.success("提交申请成功");
					}
				});
			},
			// 金额确认
			onSubmitSum() {
				this.$refs.ruleFormSum.validate((valid) => {
					if (valid) {
						this.apply(this.sumForm);
					} else {
						console.log("error submit!!");
						return false;
					}
				});
			},
		},
	};
</script>
