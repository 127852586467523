<template>
	<!-- 个人中心 账户信息卡片 -->
	<a-card :bordered="false" class="header-solid h-full card-profile-information"
		:bodyStyle="{ paddingTop: 0, paddingBottom: 0 }">
		<template #title>
			<h6 class="font-semibold m-0">使用统计</h6>
		</template>

		<a-descriptions title="" :column="1">
			<!-- <a-descriptions-item label="积分">9934</a-descriptions-item> -->
			<!-- <a-descriptions-item label="可提现佣金">{{ statisticInfo.reseller_money }}</a-descriptions-item> -->
			<!-- <a-descriptions-item label="直推总人数">{{ statisticInfo.direct_user }}</a-descriptions-item> -->
			<!-- <a-descriptions-item label="总对话次数">{{ statisticInfo.msg_count }}</a-descriptions-item> -->
			<!-- <a-descriptions-item label="总文案次数">{{ statisticInfo.write_count }}</a-descriptions-item> -->
			<a-descriptions-item label="角色对话数">{{ statisticInfo.rule_count }}</a-descriptions-item>
			<!-- <a-descriptions-item label="总绘画次数">{{ statisticInfo.draw_count }}</a-descriptions-item> -->
		</a-descriptions>

		<!-- <a-descriptions title="积分" :column="1">
			<a-descriptions-item label="积分">9934</a-descriptions-item>
		</a-descriptions>
		<hr class="my-10" style="opacity: 0.2" />
		<a-descriptions title="分销" :column="1">
			<a-descriptions-item label="可提现佣金">100</a-descriptions-item>
			<a-descriptions-item label="直推总人数">100</a-descriptions-item>
		</a-descriptions>
		<hr class="my-10" style="opacity: 0.2" />
		<a-descriptions title="次数" :column="1">
			<a-descriptions-item label="总对话次数">100</a-descriptions-item>
			<a-descriptions-item label="总创作次数">100</a-descriptions-item>
			<a-descriptions-item label="总绘画次数">100</a-descriptions-item>
		</a-descriptions> -->
	</a-card>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from "vuex"
export default {
	data() {
		return {}
	},
	computed: {
		...mapGetters("user", ["statisticInfo"])
	}
}
</script>



<style lang="scss" scoped>
::v-deep .ant-descriptions .ant-descriptions-item-label {
	font-weight: normal !important;
}

::v-deep .ant-descriptions .ant-descriptions-item-content {
	font-weight: normal !important;
}
</style>